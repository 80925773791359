/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100% !important;
}

body {
  max-width: 1000px;
  margin: 0 auto;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
